import Vue from 'vue'

import axios from 'axios'
import store from '@/store'

const token = localStorage.getItem('token')
// const token=   store.state.token;


const axiosIns = axios.create({

  // https://baraem.prokoders-staging-1.xyz
  // http://192.168.1.250:8000
  baseURL: process.env.VUE_APP_ROOT_API,


  // timeout: 1000,
  headers: { Accept: 'application/json' },
})
// console.log(process.env.VUE_APP_ROOT_API
// )
Vue.prototype.$http = axiosIns


// let serverIp= localStorage.getItem("serverIp") 192.168.1.250

// Vue.prototype.$http.defaults.baseURL=serverIp

export default axiosIns
