export default [
  {
    path: '/apps/department',
    name: 'department',
    component: () => import('@/views/apps/department/add-department.vue'),
  },
  
 
  {

    path: '/apps/all-benificary',
    name: 'all-benificary',
    component: () => import('@/views/apps/allBenficiaryForRoles/List.vue'),
    meta: {
      resource: 'all_benificary',
      action: 'all',
    },
  },
  {
    path: '/apps/show-beneficiary-info/:id',
    name: 'show-beneficiary-info',
    component: () => import('@/views/apps/allBenficiaryForRoles/showBen/show-beneficiary.vue'),
    meta: {
      action: 'all',
      resource: 'all_benificary',
    },
  },
  {
    path: '/apps/finance-accounts',
    name: 'finance-accounts',
    component: () => import('@/views/apps/account-guide/add-account.vue'),
    meta: {
      resource: 'finance_accounts',
      action: 'all',
    },
  },

  {
    path: '/apps/finance-accounts/:id',
    name: 'finance-accounts-id',
    component: () => import('@/views/apps/account-guide/edit-account.vue'),
    meta: {
      resource: 'finance_accounts',
      action: 'all',
    },
  },
  /// ////////////// Report details

  
  /// ////////////// archives


  /// ////////////// warehouse

 
 
  /// ////////////// purches Order

 

  // *===============================================---*
  // *--------- Families -------------------------------*
  {
    path: '/apps/manageServer',
    name: 'manageSite',
    component: () => import('@/views/apps/manage.vue'),
    meta: {
      resource: 'modification_requests',
      action: 'read',
    },
  },
  {
    path: '/apps/un-completed-families',
    name: 'un-completed-families',
    component: () => import('@/views/apps/unCompletedFamilies/FamiliesList.vue'),
    meta: {
      resource: 'beneficiaries',
      action: 'read',
    },
  },
  {
    path: '/apps/families-uncompleted/:id',
    name: 'families-uncompleted',
    component: () => import('@/views/apps/unCompletedFamilies/Edit-Family-Uncompleted.vue'),
    meta: {
      resource: 'beneficiaries',
      action: 'read',
    },
  },
  {
    path: '/apps/families-Beneficiaries-uncompleted/:id',
    name: 'families-Beneficiaries-uncompleted',
    component: () => import('@/views/apps/unCompletedFamilies/FamiliesBenefList.vue'),
    meta: {
      resource: 'beneficiaries',
      action: 'read',
    },
  },
  {
    path: '/apps/modification-requests',
    name: 'modification-requests',
    component: () => import('@/views/apps/families/required-modifications/modificationsList.vue'),
    meta: {
      resource: 'modification_requests',
      action: 'read',
    },
  },
  {
    path: '/apps/add-diseases',
    name: 'add-diseases',
    component: () => import('@/views/apps/diseases/add-diseases.vue'),
    meta: {
      resource: 'diseases',
      action: 'read',
    },
  },
  {
    path: '/apps/additional-requests',
    name: 'additional-requests',
    component: () => import('@/views/apps/families/required-modifications/additionList.vue'),
    meta: {
      resource: 'modification_requests',
    },
  },
 
  {
    path: '/apps/show-addition/:id',
    name: 'show-addition',
    component: () => import('@/views/apps/families/required-modifications/show-addition.vue'),
    meta: {
      resource: 'modification_requests',
    },
  },
  {
    path: '/apps/Allfamilies',
    name: 'all-families',
    component: () => import('@/views/apps/families/all-families/FamiliesList.vue'),
    meta: {
      action: 'read',
      resource: 'beneficiaries',
    },
  },
  {
    path: '/apps/families-beneficiaries/:id',
    name: 'families-Beneficiaries',
    component: () => import('@/views/apps/families/family-beneficiaries/FamiliesBenefList.vue'),
    meta: {
      action: 'read',
      resource: 'beneficiaries',
    },
  },
  {
    path: '/apps/edit-families-details/:id',
    name: 'edit-families-details',
    component: () => import('@/views/apps/families/family-beneficiaries/Edit-Family-Details.vue'),
    meta: {
      action: 'read',
      resource: 'beneficiaries',
    },
  },

  // {
  //   path: '/apps/Assessment-Form',
  //   name: 'Assessment-Form',
  //   component: () => import('@/views/apps/Assessment-Form/assessment-form.vue'),
  // },
  // {
  //   path: '/apps/Assessment-Form',
  //   name: 'Assessment-Form',
  //   component: () => import('@/views/apps/Assessment-Form/assessment-form.vue'),
  // },

  {
    path: '/apps/Allbeneficiaries/edit-beneficiary/:id',
    name: 'edit-beneficiary',
    component: () => import('@/views/apps/families/family-beneficiaries/edit-beneficiary/edit-beneficiary.vue'),
    meta: {
      action: 'read',
      resource: 'beneficiaries',
    },
  },
  {
    path: '/apps/Allbeneficiaries/show-beneficiary/:id',
    name: 'show-beneficiary',

    component: () => import('@/views/apps/families/family-beneficiaries/show-benficiary/show-beneficiary.vue'),
    meta: {
      action: 'read',
      resource: 'beneficiaries',
    },

  },
  {
    path: '/apps/Allbeneficiaries/show-beneficiary/:id',
    name: 'show-beneficiary-services',

    component: () => import('@/views/apps/families/family-beneficiaries/show-benficiary/show-beneficiary.vue'),
    meta: {
      action: 'read',
      resource: 'services',
    },

  },
  // {
  //   path: '/apps/Assessment-Form/:id',
  //   name: 'edit',
  //   component: () => import('@/views/apps/Allbeneficiaries/edit.vue'),
  // },
  // {
  //   path: '/apps/Assessment-Form/medical-referral',
  //   name: 'medical-referral',
  //   component: () => import('@/views/apps/Assessment-Form/medical-referral/medical-referral.vue'),
  // },
  // {
  //   path: '/apps/Assessment-aid/',
  //   name: 'Assessment-aid',
  //   component: () => import('@/views/apps/Assessment-aid/assessment-aid.vue'),
  // },
  // {
  //   path: '/apps/psychosocial-support/',
  //   name: 'psychosocial-support',
  //   component: () => import('@/views/apps/psychosocial-support/psychosocial-support.vue'),
  // },
  // {
  //   path: '/apps/Incident-Recorder/',
  //   name: 'incident-recorder',
  //   component: () => import('@/views/apps/Incident-Recorder/incident-recorder.vue'),
  // },
  // {
  //   path: '/apps/Survivor-plan/',
  //   name: 'Survivor-plan',
  //   component: () => import('@/views/apps/Survivor-plan/Survivor-plan.vue'),
  // },
  // {
  //   path: '/apps/Case-follow-up/',
  //   name: 'Case-follow-up',
  //   component: () => import('@/views/apps/Case-follow-up/case-follow.vue'),
  // },
  // {
  //   path: '/apps/case-close-up/',
  //   name: 'case-close-up',
  //   component: () => import('@/views/apps/case-close-up/case-close.vue'),
  // },
  // {
  //   path: '/apps/Consent-Form/',
  //   name: 'Consent-Form',
  //   component: () => import('@/views/apps/Consent-Form/consent-form.vue'),
  // },
  // {
  //   path: '/apps/Referral-Form/',
  //   name: 'Referral-Form',
  //   component: () => import('@/views/apps/Referral-Form/referral-form.vue'),
  // },
  // {
  //   path: '/apps/monitoring-paper/',
  //   name: 'monitoring-paper',
  //   component: () => import('@/views/apps/monitoring-paper/monitoring-paper.vue'),
  // },
  // {
  //   path: '/apps/educationl-programs/',
  //   name: 'educationl-programs',
  //   component: () => import('@/views/apps/educationl-programs/educationl-programs.vue'),
  // },
  // {
  //   path: '/apps/evaluation-questionnaire/',
  //   name: 'evaluation-question',
  //   component: () => import('@/views/apps/evaluation-questionnaire/evaluation-question.vue'),
  // },
  // {
  //   path: '/apps/emergency',
  //   name: 'emergency',
  //   component: () => import('@/views/apps/emergency/emergency.vue'),
  // },

  {
    path: '/apps/add-beneficiary/',
    name: 'add-beneficiary',
    component: () => import('@/views/apps/add-beneficiary/family-file/FormWizard.vue'),
    meta: {
      // action:'read',
      resource: 'beneficiaries',
    },
  },
  //     Services

  {
    path: '/apps/services/service-report/:id',
    name: 'service-report',
    component: () => import('@/views/apps/services-beneficiary-list/service-report.vue'),
    meta: {
      action: 'read',
      resource: 'services',
    },
  },
  {
    path: '/apps/services/show-beneficiary-data/:id',
    name: 'show-beneficiary-data',
    component: () => import('@/views/apps/services-beneficiary-list/showBenServices/show-beneficiary.vue'),
    meta: {
      action: 'read',
      resource: 'services',
    },
  },
//show family information
  {
    path: '/apps/services/show-family-info/:id',
    name: 'show-family-data',
    component: () => import('@/views/apps/services-beneficiary-list/showBenServices/show-family-info.vue'),
    meta: {
      action: 'read',
      resource: 'services',
    },
  },
  {
    path: '/apps/services/service-committe-report/:id',
    name: 'service-committe-report',
    component: () => import('@/views/apps/services-beneficiary-list/committe-report.vue'),
    meta: {
      action: 'read',
      resource: 'services',
    },
  },
  {
    path: '/apps/services/service-transfer/:id',
    name: 'service-transfer',
    component: () => import('@/views/apps/services-beneficiary-list/service-transfer.vue'),
    meta: {
      action: 'read',
      resource: 'services',
    },
  },
  {
    path: '/apps/services/transfer-list',
    name: 'transfer-list',
    component: () => import('@/views/apps/ServiceTransfer/index.vue'),
    meta: {
      action: 'read',
      resource: 'services',
    },
  },
  {
    path: '/apps/ServiceListTransfer',
    name: 'ServiceListTransfer',
    component: () => import('@/views/apps/Services/ServiceList.vue'),
    meta: {
      resource: 'all',
      action: 'manage',
    },
  },
  {
    path: '/apps/services/show-service-report/:id',
    name: 'show-service-report',
    component: () => import('@/views/apps/services-beneficiary-list/show-service-report.vue'),
    meta: {
      action: 'read',
      resource: 'services',
    },
  },
  // Roles
  // {
  //   path: '/pages/miscellaneous/not-authorized',
  //   name: 'misc-not-authorized',
  //   component: () => import('@/views/pages/miscellaneous/NotAuthorized.vue'),
  //   meta: {
  //     layout: 'full',
  //     action: 'read',

  //   },

  // },
  // {
  //   path: '/apps/roles/allroles',
  //   name: 'all-roles',
  //   component: () => import('@/views/apps/roles/allroles.vue'),
  //   meta: {
  //     action: 'read',
  //     resource: 'roles',
  //   },
  // },
  {
    path: '/apps/roles/showPermission/:id',
    name: 'showPermission',
    component: () => import('@/views/apps/roles/showPermission.vue'),
    meta: {
      action: 'read',
      resource: 'roles',
    },
  },
  {
    path: '/apps/roles/addroles',
    name: 'addrole',
    component: () => import('@/views/apps/roles/addroles.vue'),
    meta: {
      action: 'read',
      resource: 'roles',
    },
  },
  /// //////////achievement_targets//////////////

  /// //////////pacts//////////////

   /// //////////volunteer//////////////
   
  
   /// SESSION 
   //New Permission 
   

  /// LOCATION
  {
    path: '/apps/location/add-location',
    name: 'add-location',
    component: () => import('@/views/apps/location/add-location.vue'),
    meta: {
      action: 'read',
      resource: 'beneficiaries',
    },
  },
  {
    path: '/apps/CountryList',
    name: 'CountryList',
    component: () => import('@/views/apps/location/locations/CountryList.vue'),
    meta: {
      // action:'read',
      // resource:'beneficiaries'
    },
  },
  /// STATISTICS
  {
    path: '/apps/statistics-of-beneficiaries',
    name: 'Statistics',
    component: () => import('@/views/apps/statistics-of-beneficiaries/statistics.vue'),
    meta: {
      resource: 'statistics',
      action: 'read',
    },
  },
  {
    path: '/apps/achievement_stats',
    name: 'achievement_stats',
    component: () => import('@/views/apps/achievement_stats/index.vue'),
    meta: {
      resource: 'goals',
      action: 'all',
    },
  },

  {
    path: '/apps/beneficiaries-list',
    name: 'beneficiaries-list',
    component: () => import('@/views/apps/advanced-statistics/beneficiary-list.vue'),
    meta: {
      resource: 'statistics',
      action: 'all',
    },
  },

  {
    path: '/apps/SubServiceList/:id',
    name: 'SubServiceList',
    component: () => import('@/views/apps/Services/SubServList.vue'),
    meta: {
      resource: 'all',
      action: 'manage',
    },
  },
  {
    path: '/apps/location/add-service',
    name: 'add-service',
    component: () => import('@/views/apps/Services/add-service.vue'),
    meta: {
      resource: 'all',
      action: 'manage',
    },
  },
  {
    path: '/apps/GovernateList/:id',
    name: 'GovernateList',
    component: () => import('@/views/apps/location/locations/GovernateList.vue'),
    meta: {
      action: 'read',
      resource: 'beneficiaries',
    },
  },
  {
    path: '/apps/CitiesList/:id',
    name: 'CitiesList',
    component: () => import('@/views/apps/location/locations/CitiesList.vue'),
    meta: {
      action: 'read',
      resource: 'beneficiaries',
    },
  },
  {
    path: '/apps/VillagesList/:id',
    name: 'VillagesList',
    component: () => import('@/views/apps/location/locations/VillagesList.vue'),
    meta: {
      action: 'read',
      resource: 'beneficiaries',
    },
  },
  // WORK
  {
    path: '/apps/add-work',
    name: 'add-work',
    component: () => import('@/views/apps/add-work/add-work.vue'),
    meta: {
      action: 'read',
      resource: 'works',
    },
  },
  {
    path: '/apps/work/edit-work/:id',
    name: 'edit-work',
    component: () => import('@/views/apps/add-work/edit-work.vue'),
    meta: {
      action: 'read',
      resource: 'works',
    },
  },
   // PERSON STATUS
   {
    path: '/apps/add-person-status',
    name: 'add-person-status',
    component: () => import('@/views/apps/add-person-status/add-person-status.vue'),
    meta: {
      resource: 'all',
      action: 'manage',
    },
  },
  {
    path: '/apps/person-status/edit-person-status/:id',
    name: 'edit-person-status',
    component: () => import('@/views/apps/add-person-status/edit-person-status.vue'),
    meta: {
      resource: 'all',
      action: 'manage',
    },
  },
  // DOCUMENTS
  {
    path: '/apps/add-document',
    name: 'add-document',
    component: () => import('@/views/apps/add-document/add-document.vue'),
    meta: {
      resource: 'all',
      action: 'manage',
    },
  },
  {
    path: '/apps/document/edit-document/:id',
    name: 'edit-document',
    component: () => import('@/views/apps/add-document/edit-document.vue'),
    meta: {
      resource: 'all',
      action: 'manage',
    },
  },
  // WEAKNESS
  {
    path: '/apps/add-weakpoint',
    name: 'add-weakpoint',
    component: () => import('@/views/apps/add-weakpoint/add-weakpoint.vue'),
    meta: {
      action: 'read',
      resource: 'weaknesses',
    },
  },
  {
    path: '/apps/edit-weakpoint/:id',
    name: 'edit-weakpoint',
    component: () => import('@/views/apps/add-weakpoint/edit-weakpoint.vue'),
    meta: {
      action: 'read',
      resource: 'weaknesses',
    },
  },
  {
    path: '/verfication-code',
    name: 'verfication-code',
    component: () => import('@/views/pages/authentication/VerficationCode.vue'),
    meta: {
      layout: 'full',
    },

  },
  // SERVICE
  {
    path: '/apps/add-beneficiary/:id',
    name: 'services',
    component: () => import('@/views/apps/add-beneficiary/family-file/services.vue'),
    meta: {
      action: 'read',
      resource: 'services',
    },
  },
  {
    path: '/apps/add-beneficiary/:id',
    name: 'family-card',
    component: () => import('@/views/apps/add-beneficiary/family-file/family-card.vue'),
    meta: {
      action: 'read',
      resource: 'beneficiaries',
    },
  },
  // {
  //   path: '/apps/education-tracking',
  //   name: 'education-tracking',
  //   component: () => import('@/views/apps/education-tracking/education-tracking.vue'),
  // },
  // problem
  // *===============================================---*
  // *--------- candidates ---- ---------------------------------------*
  // *===============================================---*

  
 
  {
    path: '/apps/complains/update-complains/:id',
    name: 'update-complains',
    component: () => import('@/views/apps/problems/problems-list/EditBeneficiaryProblem.vue'),
    meta: {
      action: 'all',
      resource: 'complaints',
    },
  },
  {
    path: '/apps/complains/reply-complains/:id',
    name: 'reply-complains',
    component: () => import('@/views/apps/problems/problems-list/replyProblem.vue'),
    meta: {
      action: 'all',
      resource: 'complaints',
    },
  },
  {
    path: '/apps/complains/add-complain',
    name: 'add-complain-benficiary',
    component: () => import('@/views/apps/problems/problems-list/AddBeneficiaryProblem.vue'),
    meta: {
      action: 'all',
      resource: 'complaints',
    },
  },
  {
    path: '/apps/problem/problems-list',
    name: 'problems-list',
    component: () => import('@/views/apps/problems/problems-list/problems-list.vue'),
    meta: {
      action: 'all',
      resource: 'complaints',
    },
  },

  //////////////CONVERSATIONS//////////////////////
 
 

  // *===============================================---*
  // *--------- USER ---- ---------------------------------------*
  // *===============================================---*


 
  // *===============================================---*
  // *--------- requestAuditing ---- ---------------------------------------*
  // *===============================================---*

  {
    path: '/apps/services/request-auditing',
    name: 'request-auditing',
    component: () => import('@/views/apps/services-beneficiary-list/request-auditing.vue'),
    meta: {
      resource: 'services',
      action: 'read',
    },
  },
  // *===============================================---*
  // *--------- leaves-approve ---- ---------------------------------------*
  // *===============================================---*


  /// ////////////////////salary/////////////////

  
  /// ////////////////////Out In come /////////////////
 
  

 
  /// ////////////////////ORV /////////////////
  
  /// ////////////////////DONOR /////////////////
  
  /// ////////////////////UNITS /////////////////

  /// ////////////////////warehouse /////////////////
 
  /// ////////////////////currency /////////////////

  /// ////////////////////VENDOR /////////////////
  
  

  
  /// ////////////////////FINANCE /////////////////
 

  /// ////////////////////ITEM /////////////////
  
  
  /// ////////////////////INFORM /////////////////
  

  /// ////////////////////EXCHANGE RATE/////////////////

  
  /// ////////////////////Bill /////////////////

  
  /// ////////////////////PRICE OFFER /////////////////


  /// ////////////////////PURCHES ORDER /////////////////

 
  
  /// ////////////////////PURCHES CONTARCE /////////////////

 
  /// ////////////////////reward/////////////////





  
  /// ///////////PAYMENT///////////////
 

  {
    path: '/apps/users/user-profile',
    name: 'user-profile',
    component: () => import('@/views/apps/user/user-profile/Profile.vue'),
    meta: {
      action: 'read',
      resource: 'users',
    },
  },
  {
    path: '/apps/users/add',
    name: 'users-add',
    component: () => import('@/views/apps/user/users-add/UsersAdd.vue'),
    meta: {
      action: 'read',
      resource: 'users',
    },
  },
  {
    path: '/apps/users/edit/:id',
    name: 'users-edit',
    component: () => import('@/views/apps/user/users-edit/UsersEdit.vue'),
    meta: {
      action: 'read',
      resource: 'users',
    },
  },

  // *===============================================---*
  // *--------- Holidays -------------------------------*
  // *===============================================---*
  
 

  
  

  
  // *===============================================---*
  // *--------- Attendance -------------------------------*
  // *===============================================---*
  // {
  //   path: '/apps/attendance',
  //   name: 'Attendance',
  //   component: () => import('@/views/apps/attendance/AttendanceList.vue'),

  // },
  // {
  //   path: '/apps/leave-type',
  //   name: 'Attendance',
  //   component: () => import('@/views/apps/attendance/leave-Type/LeaveList.vue'),

  // },
  // {
  //   path: '/apps/leave-Application',
  //   name: 'Leave-Application',
  //   component: () => import('@/views/apps/attendance/leave-application/LeaveAppList.vue'),

  // },

  // {
  //   path: '/apps/email/:folder',
  //   name: 'apps-email-folder',
  //   component: () => import('@/views/apps/email/Email.vue'),
  //   meta: {
  //     contentRenderer: 'sidebar-left',
  //     contentClass: 'email-application',
  //     navActiveLink: 'apps-email',
  //   },
  //   beforeEnter(to, _, next) {
  //     if (['sent', 'draft', 'starred', 'spam', 'trash'].includes(to.params.folder)) next()
  //     else next({ name: 'error-404' })
  //   },
  // },
  // {
  //   path: '/apps/email/label/:label',
  //   name: 'apps-email-label',
  //   component: () => import('@/views/apps/email/Email.vue'),
  //   meta: {
  //     contentRenderer: 'sidebar-left',
  //     contentClass: 'email-application',
  //     navActiveLink: 'apps-email',
  //   },
  //   beforeEnter(to, _, next) {
  //     if (['personal', 'company', 'important', 'private'].includes(to.params.label)) next()
  //     else next({ name: 'error-404' })
  //   },
  // },

  // // *===============================================---*
  // // *--------- TODO & IT'S FILTERS N TAGS ---------------------------------------*
  // // *===============================================---*
  // {
  //   path: '/apps/todo',
  //   name: 'apps-todo',
  //   component: () => import('@/views/apps/todo/Todo.vue'),
  //   meta: {
  //     contentRenderer: 'sidebar-left',
  //     contentClass: 'todo-application',
  //   },
  // },
  // {
  //   path: '/apps/todo/:filter',
  //   name: 'apps-todo-filter',
  //   component: () => import('@/views/apps/todo/Todo.vue'),
  //   meta: {
  //     contentRenderer: 'sidebar-left',
  //     contentClass: 'todo-application',
  //     navActiveLink: 'apps-todo',
  //   },
  //   beforeEnter(to, _, next) {
  //     if (['important', 'completed', 'deleted'].includes(to.params.filter)) next()
  //     else next({ name: 'error-404' })
  //   },
  // },
  // {
  //   path: '/apps/todo/tag/:tag',
  //   name: 'apps-todo-tag',
  //   component: () => import('@/views/apps/todo/Todo.vue'),
  //   meta: {
  //     contentRenderer: 'sidebar-left',
  //     contentClass: 'todo-application',
  //     navActiveLink: 'apps-todo',
  //   },
  //   beforeEnter(to, _, next) {
  //     if (['team', 'low', 'medium', 'high', 'update'].includes(to.params.tag)) next()
  //     else next({ name: 'error-404' })
  //   },
  // },

  // *===============================================---*
  // *--------- CHAT  ---------------------------------------*
  // *===============================================---*
  // {
  //   path: '/apps/chat',
  //   name: 'apps-chat',
  //   component: () => import('@/views/apps/chat/Chat.vue'),
  //   meta: {
  //     contentRenderer: 'sidebar-left',
  //     contentClass: 'chat-application',
  //   },
  // },

  // *===============================================---*
  // *--------- ECOMMERCE  ---------------------------------------*
  // *===============================================---*
  // {
  //   path: '/apps/e-commerce/shop',
  //   name: 'apps-e-commerce-shop',
  //   component: () => import('@/views/apps/e-commerce/e-commerce-shop/ECommerceShop.vue'),
  //   meta: {
  //     contentRenderer: 'sidebar-left-detached',
  //     contentClass: 'ecommerce-application',
  //     pageTitle: 'Shop',
  //     breadcrumb: [
  //       {
  //         text: 'ECommerce',
  //       },
  //       {
  //         text: 'Shop',
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // {
  //   path: '/apps/e-commerce/wishlist',
  //   name: 'apps-e-commerce-wishlist',
  //   component: () => import('@/views/apps/e-commerce/e-commerce-wishlist/ECommerceWishlist.vue'),
  //   meta: {
  //     pageTitle: 'Wishlist',
  //     contentClass: 'ecommerce-application',
  //     breadcrumb: [
  //       {
  //         text: 'ECommerce',
  //       },
  //       {
  //         text: 'Wishlist',
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // {
  //   path: '/apps/e-commerce/checkout',
  //   name: 'apps-e-commerce-checkout',
  //   component: () => import('@/views/apps/e-commerce/e-commerce-checkout/ECommerceCheckout.vue'),
  //   meta: {
  //     pageTitle: 'Checkout',
  //     contentClass: 'ecommerce-application',
  //     breadcrumb: [
  //       {
  //         text: 'ECommerce',
  //       },
  //       {
  //         text: 'Checkout',
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // {
  //   path: '/apps/e-commerce/:slug',
  //   name: 'apps-e-commerce-product-details',
  //   component: () => import('@/views/apps/e-commerce/e-commerce-product-details/ECommerceProductDetails.vue'),
  //   meta: {
  //     pageTitle: 'Product Details',
  //     contentClass: 'ecommerce-application',
  //     breadcrumb: [
  //       {
  //         text: 'ECommerce',
  //       },
  //       {
  //         text: 'Shop',
  //         active: true,
  //       },
  //       {
  //         text: 'Product Details',
  //         active: true,
  //       },
  //     ],
  //   },
  // },

  // *===============================================---*
  // *--------- USER ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/apps/users/list',
    name: 'apps-users-list',
    component: () => import('@/views/apps/user/users-list/UsersList.vue'),
  },
  // {
  //   path: '/apps/users/view/:id',
  //   name: 'apps-users-view',
  //   component: () => import('@/views/apps/user/users-view/UsersView.vue'),
  // },
  {
    path: '/apps/users/edit/:id',
    name: 'apps-users-edit',
    component: () => import('@/views/apps/user/users-edit/UsersEdit.vue'),
  },

  // Invoice
  // {
  //   path: '/apps/invoice/list',
  //   name: 'apps-invoice-list',
  //   component: () => import('@/views/apps/invoice/invoice-list/InvoiceList.vue'),
  // },
  // {
  //   path: '/apps/invoice/preview/:id',
  //   name: 'apps-invoice-preview',
  //   component: () => import('@/views/apps/invoice/invoice-preview/InvoicePreview.vue'),
  // },
  // {
  //   path: '/apps/invoice/add/',
  //   name: 'apps-invoice-add',
  //   component: () => import('@/views/apps/invoice/invoice-add/InvoiceAdd.vue'),
  // },
  // {
  //   path: '/apps/invoice/edit/:id',
  //   name: 'apps-invoice-edit',
  //   component: () => import('@/views/apps/invoice/invoice-edit/InvoiceEdit.vue'),
  // },
]
